<i18n>
{
  "en": {
    "create-title": "New Task",
    "open-deal": "Open related card",
    "delete": "Delete task",
    "not-found": "You don't have any task type registered yet",
    "not-found-description": "Click on button below to create a new one",
    "create-task-type": "Create task type",
    "input-1": "Type",
    "input-2": "Status",
    "input-3": "Name (optional)",
    "input-4": "Owner",
    "input-5": "Start date",
    "input-6": "Start hour",
    "input-7": "End date",
    "input-8": "End hour",
    "input-9": "Note",
    "input-10": "Task completed",
    "created-at": "Created at",
    "updated-at": "Updated at",
    "create": "Create task",
    "update": "Save updates"
  },
  "pt-BR": {
    "create-title": "Nova Tarefa",
    "open-deal": "Abrir card",
    "delete": "Deletar tarefa",
    "not-found": "Você não possui nenhum tipo de tarefa cadastrado",
    "not-found-description": "Clique no botão abaixo para criar",
    "create-task-type": "Criar tipo de tarefa",
    "input-1": "Tipo de tarefa",
    "input-2": "Status da tarefa",
    "input-3": "Nome da tarefa (opcional)",
    "input-4": "Responsável da tarefa",
    "input-5": "Data de início",
    "input-6": "Hora de início",
    "input-7": "Data final",
    "input-8": "Hora final",
    "input-9": "Nota",
    "input-10": "Tarefa concluída",
    "created-at": "Criado em",
    "updated-at": "Última atualização",
    "create": "Criar tarefa",
    "update": "Salvar"
  }
}
</i18n>

<template>
  <modal-action @close="closeTask" :columns="2">
    <template #view>
      <div class="modal">
        <div class="modal-header">
          <div class="name task-name">
            <div class="icon">
              <font-awesome-icon icon="tasks" />
            </div>
            <template v-if="!taskExist">
              {{ $t("create-title") }}
            </template>
            <template v-else>
              <img
                v-if="
                  task &&
                    task.source &&
                    task.source.length > 0 &&
                    ['google', 'outlook'].includes(task.source)
                "
                :src="imageSource(task.source)"
              />
              <we-text-tooltip :text="getTaskName(task)" lines="1" />
            </template>
          </div>
          <div class="options">
            <we-icon-button
              v-if="taskExist && isTasksList && task.deal_id"
              icon="note-sticky"
              :name="$t('open-deal')"
              @click="openDeal"
            />
            <we-icon-button
              v-if="taskExist"
              :icon="{ prefix: 'far', iconName: 'trash-alt' }"
              :name="$t('delete')"
              @click="deleteTask"
              cy="delete-task"
            />
            <we-icon-button icon="times" @click="closeTask" />
          </div>
        </div>
        <v-form ref="form" id="modal-body_task" class="modal-body">
          <we-not-found v-if="!hasTaskList">
            <template #title>
              {{ $t("not-found") }}
            </template>
            <template #description>
              {{ $t("not-found-description") }}
            </template>
            <template #actions>
              <we-button
                class="green"
                :text="$t('create-task-type')"
                icon="external-link-alt"
                @click="$router.push({ name: 'tasksConfigs' })"
              />
            </template>
          </we-not-found>
          <template v-if="hasTaskList && !loading">
            <div class="fields">
              <we-input
                type="task"
                :label="$t('input-1')"
                :value="task.task_type"
                :items="taskTypesList"
                @input="selectTask"
                is-required
              />
              <we-input
                type="text"
                :label="$t('input-3')"
                v-model="task.name"
                cy="create-task-name"
              />
              <we-input
                type="select"
                :label="$t('input-2')"
                :clearable="false"
                :value="task.status"
                :items="statusList"
                @input="selectStatus"
                cy="create-task-status"
              />
              <we-input-user
                :label="$t('input-4')"
                :value="task.responsible_user_id"
                @input="task.responsible_user_id = $event.id"
                cy="create-task-user"
              />
              <we-input
                v-model="task.startDate"
                type="date"
                :label="$t('input-5')"
                @input="changeStartDate"
                :clearable="false"
              />
              <we-input
                v-model="task.startHour"
                type="time"
                :label="$t('input-6')"
                :clearable="false"
              />
              <we-input
                v-model="task.endDate"
                :minDate="task.startDate"
                type="date"
                :label="$t('input-7')"
                :clearable="false"
              />
              <we-input
                v-model="task.endHour"
                type="time"
                :label="$t('input-8')"
                :clearable="false"
              />
              <we-input
                class="double-column"
                :label="$t('input-9')"
                v-model="task.note"
                type="textarea"
                rows="1"
              />
              <v-switch
                class="double-column"
                v-model="task.is_finished"
                :label="$t('input-10')"
                hide-details
              />
            </div>
          </template>
        </v-form>
        <div class="bottom" :class="{ 'has-history': taskExist }">
          <div class="history" v-if="taskExist">
            <div class="icon">
              <font-awesome-icon icon="history" />
            </div>
            <div class="data">
              <div class="item">
                {{ $t("created-at") }}:
                <span>
                  {{ task.created_at | dateTime }}
                  ({{ getUserFullName() }})
                </span>
              </div>
              <div class="item">
                {{ $t("updated-at") }}:
                <span>{{ task.updated_at | dateTime }}</span>
              </div>
            </div>
          </div>
          <we-button
            class="green"
            :class="{ 'mobile-icon': taskExist }"
            :icon="taskExist ? 'check' : 'plus'"
            :text="taskExist ? $t('update') : $t('create')"
            :disabled="!hasTaskList"
            :loading="btnLoading"
            @click="taskAction"
            cy="create-task"
          />
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      btnLoading: false,
      loading: true,
      isTasksList: false,
      taskCompleted: false,
      reload: false,
      task: {
        task_type: {},
        status: "",
        startDate: "",
        startHour: "",
        endDate: "",
        endHour: "",
        note: "",
        responsible_user_id: 0
      }
    };
  },
  computed: {
    taskExist() {
      return this.$route.params.taskId;
    },
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    },
    hasTaskList() {
      return this.taskTypesList.length > 0;
    },
    dealId() {
      return this.$route.params.dealId || 0;
    },
    taskTypesList() {
      return this.$store.getters.getTasks || [];
    },
    statusList() {
      return this.task?.task_type?.status_options || [];
    },
    currentDeal() {
      return this.$store.getters.getCurrentDeal;
    }
  },
  methods: {
    ...mapActions([
      "tasksRequest",
      "dealTaskRequest",
      "updateDealTaskRequest",
      "dealTasksRequest",
      "dealRequest",
      "usersRequest",
      "findUsersRequest",
      "deleteDealTaskRequest",
      "createDealTaskRequest"
    ]),
    imageSource(source) {
      return require(`@/assets/task-source/${source}.svg`);
    },
    changeStartDate(val) {
      const start = new Date(val);
      const end = new Date(this.task.endDate);
      if (start > end) {
        this.task.endDate = val;
      }
    },
    closeTask() {
      if (this.$route.name.includes("DealTaskInTasks")) {
        this.$router.push({
          name: "tasksDeal",
          params: { dealId: Number(this.dealId) },
          query: { reloadDeals: this.reloadDeals }
        });
      } else if (this.$route.name.includes("DealTaskInDeals")) {
        this.$router.push({
          name: "deal",
          params: { dealId: Number(this.dealId) },
          query: { reloadDeals: this.reloadDeals }
        });
      } else if (this.$route.name.includes("DealTaskInContactDeals")) {
        this.$router.push({
          name: "contactDeal",
          params: { dealId: Number(this.dealId) },
          query: { reloadDeals: this.reloadDeals }
        });
      } else if (this.$route.name.includes("DealTaskInCalendar")) {
        this.$router.push({
          name: "dealInCalendar",
          params: { dealId: Number(this.dealId) },
          query: { reloadDeals: this.reloadDeals }
        });
      } else if (this.$route.name.includes("DealTaskInTasksList")) {
        this.$router.push({
          name: "dealInTasksList",
          params: { dealId: Number(this.dealId) },
          query: { reloadDeals: this.reloadDeals }
        });
      } else if (this.$route.name.includes("TaskInCalendar")) {
        this.$router.push({
          name: "tasksCalendar",
          query: { reload: this.reload }
        });
      } else if (this.$route.name.includes("TaskInTasksList")) {
        this.$router.push({
          name: "tasksList",
          query: { reload: this.reload }
        });
      } else {
        this.$router.push({
          name: "pipelineDeal",
          params: { dealId: Number(this.dealId) },
          query: { reloadDeals: this.reloadDeals }
        });
      }
    },
    selectTask(val) {
      if (val) {
        this.task.task_type = val;
        this.task.status = val.status_options[0];
      }
    },
    selectStatus(val) {
      if (val) {
        this.task.status = val;
      }
    },
    openDeal() {
      if (this.$route.name === "editTaskInTasksList") {
        this.$router.push({
          name: "dealInTasksList",
          params: { dealId: this.currentDeal.id }
        });
      } else if (this.$route.name === "editTaskInCalendar") {
        this.$router.push({
          name: "dealInCalendar",
          params: { dealId: this.currentDeal.id }
        });
      } else {
        this.$router.push({
          name: "pipelineDeal",
          params: { dealId: this.currentDeal.id }
        });
      }
    },
    getTaskName(task) {
      return task?.name ? task?.name : task.task_type?.name;
    },
    taskAction() {
      const validate = this.$refs.form.validate();

      if (validate) {
        this.taskExist ? this.editTask() : this.createTask();
      }
    },
    async createTask() {
      this.btnLoading = true;
      this.reload = true;
      const payload = {
        name: this.task.name,
        status: this.task.status,
        start: this.task.startDate + " " + this.task.startHour,
        end: this.task.endDate + " " + this.task.endHour,
        note: this.task.note,
        deal_id: Number(this.dealId),
        creator_user_id: this.loggedUser.id,
        responsible_user_id:
          this.task.responsible_user_id || this.loggedUser.id,
        task_type_id: this.task.task_type?.id,
        is_finished: this.task.is_finished
      };
      await this.createDealTaskRequest(payload);
      if (this.dealId > 0) {
        await this.dealTasksRequest(this.dealId);
      }
      this.reloadDeals = true;
      this.closeTask();
      this.btnLoading = false;
    },
    async editTask() {
      this.btnLoading = true;
      this.reload = true;
      const payload = this.task;

      payload.start = this.task.startDate + " " + this.task.startHour;
      payload.end = this.task.endDate + " " + this.task.endHour;
      payload.task_type_id = this.task.task_type?.id;
      await this.updateDealTaskRequest(payload);
      await this.dealTasksRequest(this.dealId);
      this.reloadDeals = true;
      this.closeTask();
      this.btnLoading = false;
    },
    async deleteTask() {
      this.reload = true;
      await this.deleteDealTaskRequest(this.task.id);
      await this.dealTasksRequest(this.dealId);
      this.closeTask();
    },
    getUserFullName() {
      return this.task?.creator_user
        ? `${this.task?.creator_user.first_name} ${this.task?.creator_user.last_name}`
        : `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
    },
    async doRequests() {
      await this.dealTaskRequest(this.$route.params.taskId);
      this.task = this.$store.getters.getCurrentDealTask;

      document.title = this.getTaskName(this.task);

      if (this.task.deal_id) {
        await this.dealRequest(this.task.deal_id);
      }
      this.task.task_type =
        this.taskTypesList.find(e => e.id === this.task.task_type?.id) || 0;
    }
  },
  async mounted() {
    await this.tasksRequest();
    await this.usersRequest();

    if (!this.taskExist) {
      if (this.taskTypesList.length > 0) {
        this.task.task_type = this.taskTypesList[0] || "";
        this.task.status = this.statusList[0] || "";
        const now = new Date();
        const later = new Date(now.getTime() + 30 * 60000);
        const options = {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false
        };

        this.task.startDate = this.$options.filters.isoDate(now).split("T")[0];

        this.task.startHour = now
          .toLocaleDateString("pt-BR", options)
          .split(" ")[1];
        this.task.endDate = this.$options.filters.isoDate(now).split("T")[0];
        this.task.endHour = later
          .toLocaleDateString("pt-BR", options)
          .split(" ")[1];
      }
    } else {
      if (
        this.$route.name === "editTaskInTasksList" ||
        this.$route.name === "editTaskInCalendar"
      ) {
        this.isTasksList = true;
      }

      await this.doRequests();
    }

    this.loading = false;
  }
};
</script>

<style lang="scss">
#modal-body_task {
  min-height: 453px;
}
</style>
