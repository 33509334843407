<i18n>
{
  "en": {
    "drop": "Drop files to upload",
    "delete-title": "Delete card",
    "on-workflow": "on pipeline",
    "on-stage": "on stage",
    "data": "Data",
    "tasks": "Tasks",
    "new-task": "New tasks",
    "custom-fields": "Custom Fields",
    "add-fields": "Add fields",
    "attachments": "Attachments",
    "activities": "Activities",
    "comments": "Comments",
    "code": "Code",
    "card-name": "Card name",
    "value": "Value",
    "responsible": "Owner",
    "products": "Products",
    "products-value": "Price of selected products",
    "related-contacts": "Related contacts",
    "related-cards": "Related cards",
    "workflow": "Pipeline",
    "stage": "Stage",
    "external-link": "Open on new tab",
    "related-companies": "Related companies",
    "tags": "Tags",
    "abandon-reason": "Lost reason",
    "created-at": "Created at",
    "updated-at": "Updated at",
    "save": "Save updates",
    "prev": "Prev",
    "next": "Next",
    "not-found-title": "Card not found!",
    "not-found-description": "Unfortunately the card you are trying to open no longer exists, please check if the url is correct.",
    "save-error-title": "Wasn't possible to save changes!",
    "save-error-description": "Check if all required custom fields filled.",
    "new-contact": "New contact",
    "new-company": "New company"
  },
  "pt-BR": {
    "drop": "Solte os arquivos a serem carregados",
    "delete-title": "Deletar card",
    "on-workflow": "no pipeline",
    "on-stage": "no estágio",
    "data": "Dados",
    "tasks": "Tarefas",
    "new-task": "Nova tarefa",
    "custom-fields": "Campos Customizados",
    "add-fields": "Adicionar campos",
    "attachments": "Anexos",
    "activities": "Atividades",
    "comments": "Comentários",
    "code": "Código",
    "card-name": "Card",
    "value": "Valor",
    "responsible": "Responsável",
    "products": "Produtos",
    "products-value": "Valor dos produtos selecionados",
    "related-contacts": "Contatos relacionados",
    "related-cards": "Cards relacionados",
    "workflow": "Pipeline",
    "stage": "Estágio",
    "external-link": "Abrir em nova guia",
    "related-companies": "Empresas relacionadas",
    "tags": "Etiquetas",
    "abandon-reason": "Motivo de abandono",
    "created-at": "Criado em",
    "updated-at": "Última atualização",
    "save": "Salvar alterações",
    "prev": "Anterior",
    "next": "Próximo",
    "not-found-title": "O card não foi encontrado!",
    "not-found-description": "Infelizmente o card que está tentando abrir não existe mais. Confira se o link que tentou acessar está correto ou tente pesquisar pelo card novamente.",
    "save-error-title": "Não foi possível salvar alterações!",
    "save-error-description": "Verifique se os campos customizados obrigatórios foram preenchidos.",
    "new-contact": "Novo contato",
    "new-company": "Nova empresa"
  }
}
</i18n>

<template>
  <div id="pipeline_deal_view">
    <router-view />
    <transition appear name="fade">
      <we-shadow @click="closeDeal" />
    </transition>
    <transition appear name="slide-deal">
      <div id="pipeline_deal" @dragover="dragover" :class="{ dragovered }">
        <v-form ref="form" @submit.prevent class="modal">
          <div class="dragover" @dragleave="dragleave" @drop="drop">
            {{ $t("drop") }}
          </div>
          <div class="head">
            <div class="options">
              <confirm-modal
                :open="confirmDeleteOpened"
                @close="confirmDeleteOpened = false"
                @confirm="deleteDeal(deal.id)"
              />
              <we-icon-button
                class="big-mobile error--text"
                :icon="['far', 'trash-alt']"
                :name="$t('delete-title')"
                direction="bottom"
                @click="confirmDeleteOpened = true"
                cy="delete-deal"
              />
              <we-icon-button
                class="big-mobile"
                icon="times"
                @click="closeDeal"
              />
            </div>
            <div class="data">
              <div class="deal-name">
                <div class="icon">
                  <font-awesome-icon icon="note-sticky" />
                </div>
                <we-text-tooltip
                  v-if="deal"
                  class="text"
                  :text="deal.name"
                  lines="1"
                />
              </div>
              <div class="position" v-if="currentPipeline">
                {{ $t("on-workflow") }}
                <b>{{ currentPipeline ? currentPipeline.name : "" }}</b>
                {{ $t("on-stage") }}
                <b>{{ currentStage.name }}</b>
              </div>
              <div class="manage-pipelines">
                <button class="prev" @click="setPrevPipeline">
                  <font-awesome-icon icon="chevron-left" />
                  <span class="btn-text">
                    {{ $t("prev") }}
                  </span>
                </button>
                <div class="current">
                  <label class="we-label">
                    Pipeline
                  </label>
                  <div class="pipeline-name">
                    {{ currentPipeline ? currentPipeline.name : "" }}
                  </div>
                </div>
                <button class="next" @click="setNextPipeline">
                  <span class="btn-text">
                    {{ $t("next") }}
                  </span>
                  <font-awesome-icon icon="chevron-right" />
                </button>
              </div>
              <we-deal-steps
                :steps="steps"
                :current="currentStep"
                @selectStep="updateDealStage"
              />
              <we-nav-x
                v-if="mobileNavigationItems && mobileNavigationItems.length > 0"
                ref="weNavX"
                :items="mobileNavigationItems"
                :activeIndex="currentMobileNavIndex"
                @select="selectMobileNav"
              />
            </div>
          </div>
          <div class="body">
            <div
              v-if="!loading"
              class="box right hide-scrollbar"
              :class="{ 'hide-mobile': currentMobileNavIndex != 0 }"
            >
              <div class="label">
                <div class="icon">
                  <font-awesome-icon icon="key" />
                </div>
                {{ $t("code") }}
              </div>
              <we-input v-model="deal.code" type="text" readonly />
              <div class="label">
                <div class="icon">
                  <font-awesome-icon icon="note-sticky" />
                </div>
                {{ $t("card-name") }}
              </div>
              <we-input v-model="deal.name" is-required type="text" />
              <div class="label">
                <div class="icon">
                  <font-awesome-icon icon="tags" />
                </div>
                {{ $t("tags") }}
              </div>
              <we-input
                type="tags"
                v-model="deal.tags"
                :items="tagList"
                :clearable="false"
              />
              <div class="label">
                <div class="icon">
                  <font-awesome-icon icon="user-tie" />
                </div>
                {{ $t("responsible") }}
              </div>
              <we-input-user v-model="selectedUser" />
              <template
                v-if="
                  currentPipeline &&
                    currentPipeline.schema &&
                    currentPipeline.schema.show_deal_products
                "
              >
                <div class="label">
                  <div class="icon">
                    <font-awesome-icon icon="cubes" />
                  </div>
                  {{ $t("products") }}
                  <we-text-tooltip
                    class="price"
                    :text="productsTotalPrice | valueToReais"
                    :tooltipText="$t('products-value')"
                  />
                </div>
                <div
                  class="option-input"
                  :class="{ fill: selectedProducts.length === 0 }"
                >
                  <we-input-products
                    :key="updateProductInput"
                    v-model="selectedProducts"
                    @input="updateDealProducts"
                    multiple
                  />
                  <we-deal-calculator
                    v-if="selectedProducts.length > 0"
                    @updateProducts="
                      selectedProducts = $event;
                      updateDealProducts($event);
                      updateProductInput += 1;
                    "
                  />
                </div>
              </template>
              <template
                v-if="
                  currentPipeline &&
                    currentPipeline.schema &&
                    currentPipeline.schema.show_deal_amount
                "
              >
                <div class="label">
                  <div class="icon">
                    <font-awesome-icon icon="dollar-sign" />
                  </div>
                  {{ $t("value") }}
                </div>
                <we-input v-model="deal.amount" type="money"></we-input>
              </template>
              <template
                v-if="
                  currentPipeline &&
                    currentPipeline.schema &&
                    currentPipeline.schema.show_deal_contacts
                "
              >
                <div class="label">
                  <div class="icon">
                    <font-awesome-icon icon="users" />
                  </div>
                  {{ $t("related-contacts") }}
                </div>
                <div class="option-input">
                  <we-input-contacts
                    class="contacts"
                    multiple
                    v-model="selectedContacts"
                  />
                  <we-icon-button
                    icon="plus"
                    @click="openCreateContact"
                    :name="$t('new-contact')"
                  />
                </div>
                <div class="contact-list">
                  <we-deal-contact
                    v-for="contact in selectedContacts"
                    :key="contact.id"
                    :contact="contact"
                    :pipeline="currentPipeline"
                    :stage="currentStage"
                    left
                    fromDeal
                  />
                </div>
              </template>
              <template
                v-if="
                  currentPipeline &&
                    currentPipeline.schema &&
                    currentPipeline.schema.show_deal_companies
                "
              >
                <div class="label">
                  <div class="icon">
                    <font-awesome-icon icon="building" />
                  </div>
                  {{ $t("related-companies") }}
                </div>
                <div class="option-input">
                  <we-input-companies multiple v-model="selectedCompanies" />
                  <we-icon-button
                    icon="plus"
                    @click="openCreateCompany"
                    :name="$t('new-company')"
                  />
                </div>
              </template>
              <template
                v-if="
                  currentPipeline &&
                    currentPipeline.schema &&
                    currentPipeline.schema.show_deal_deals
                "
              >
                <div class="label">
                  <div class="icon">
                    <font-awesome-icon icon="note-sticky" />
                  </div>
                  {{ $t("related-cards") }}
                </div>
                <we-input-deals multiple v-model="selectedDeals" />
                <div class="related-deal-list">
                  <div
                    class="related-deal"
                    v-for="deal in selectedDeals"
                    :key="deal.id"
                  >
                    <div class="text">
                      <div class="deal-name">
                        <we-text-tooltip :text="deal.name" />
                      </div>
                      <div class="deal-description">
                        <div class="description-item">
                          {{ $t("workflow") }}:
                          <b><we-text-tooltip :text="deal.pipeline"/></b>
                        </div>
                        <div class="description-item">
                          {{ $t("stage") }}:
                          <b><we-text-tooltip :text="deal.pipeline_stage"/></b>
                        </div>
                      </div>
                    </div>
                    <div class="action">
                      <we-icon-button
                        icon="external-link-alt"
                        :name="$t('external-link')"
                        @click="openRelatedDeal(deal)"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <div class="label hide-mobile">
                <div class="icon">
                  <font-awesome-icon icon="columns" />
                </div>
                {{ $t("workflow") }}
              </div>
              <we-input
                class="hide-mobile"
                type="global-select"
                v-model="deal.pipeline_id"
                :items="mappedPipelines"
                @input="changePipeline"
              />
              <div class="label hide-mobile">
                <div class="icon">
                  <font-awesome-icon icon="signal" />
                </div>
                {{ $t("stage") }}
              </div>
              <we-input
                class="hide-mobile"
                type="global-select"
                v-model="deal.pipeline_stage_id"
                :items="mappedStages"
              />
              <div class="label">
                <div class="icon">
                  <font-awesome-icon icon="ban" />
                </div>
                {{ $t("abandon-reason") }}
              </div>
              <we-input
                v-if="currentPipeline"
                type="select"
                :value="deal.lost_reason"
                :items="currentPipeline.lost_reason_options"
                @input="setDealLostReason"
              />
            </div>
            <div class="box left hide-scrollbar">
              <section
                id="deal-tasks"
                name="deal-tasks"
                :class="{ 'hide-mobile': currentMobileNavIndex != 1 }"
              >
                <div class="title">
                  <div class="icon">
                    <font-awesome-icon icon="tasks" />
                  </div>
                  {{ $t("tasks") }}
                  <we-action
                    :text="$t('new-task')"
                    @click="openCreateDealTask"
                    cy="create-deal-task"
                  />
                </div>
                <div class="fields fill">
                  <we-deal-task
                    @update="reloadDeals = true"
                    v-for="task in dealTasks"
                    :key="task.id"
                    :task="task"
                  />
                </div>
              </section>
              <section
                id="custom_fields"
                name="custom_fields"
                :class="{ 'hide-mobile': currentMobileNavIndex != 0 }"
              >
                <div class="title">
                  <div class="icon">
                    <font-awesome-icon icon="folder-plus" />
                  </div>
                  {{ $t("custom-fields") }}
                  <we-action
                    v-if="isAdmin"
                    :text="$t('add-fields')"
                    @click="
                      $router.push({
                        name: 'editStage',
                        params: {
                          pipelineId: currentPipeline.id,
                          stageId: currentStage.id
                        }
                      })
                    "
                  />
                </div>
                <div class="fields">
                  <we-input
                    v-for="(field, i) in customFieldsInputsWithConditions"
                    :class="field.layout"
                    :key="i"
                    :type="field.type"
                    :label="field.label"
                    :items="field.values"
                    :isRequired="field.is_required"
                    :readonly="field.is_read_only"
                    :placeholder="field.placeholder"
                    v-model="dealCustomFields[field.name]"
                    :mask="field.mask"
                    cy="deal-custom-field"
                  />
                </div>
              </section>
              <section
                id="attachments"
                name="attachments"
                :class="{ 'hide-mobile': currentMobileNavIndex != 2 }"
              >
                <div class="title">
                  <div class="icon">
                    <font-awesome-icon icon="paperclip" />
                  </div>
                  {{ $t("attachments") }}
                </div>
                <div class="fields fill">
                  <drop-file
                    v-if="deal.id"
                    ref="dropFileComponent"
                    entity="deal"
                    :recordId="deal.id"
                  />
                </div>
              </section>
              <section
                id="activities"
                name="activities"
                :class="{ 'hide-mobile': currentMobileNavIndex != 3 }"
              >
                <div class="title">
                  <div class="icon">
                    <font-awesome-icon icon="tasks" />
                  </div>
                  {{ $t("activities") }}
                </div>
                <div class="fields fill">
                  <activities
                    v-if="deal.id"
                    ref="activitiesComponent"
                    entity="deal"
                    :recordId="deal.id"
                    related-messages
                  />
                </div>
              </section>
            </div>
          </div>
          <div class="bottom active">
            <div class="history">
              <div class="icon">
                <font-awesome-icon icon="history" />
              </div>
              <div class="data">
                <div class="item">
                  {{ $t("created-at") }}:
                  <span>{{ deal.created_at | dateTime }}</span>
                </div>
                <div class="item">
                  {{ $t("updated-at") }}:
                  <span>{{ deal.updated_at | dateTime }}</span>
                </div>
              </div>
            </div>
            <we-button
              @click="updateDeal"
              class="green mobile-icon"
              :text="$t('save')"
              mobile-text="Salvar"
              icon="check"
              :loading="btnLoading"
            />
          </div>
          <we-loading-overflow :loading="loading" />
          <we-not-found v-if="notFound">
            <template #title>{{ $t("not-found-title") }}</template>
            <template #description>
              {{ $t("not-found-description") }}
            </template>
            <template #picture>
              <img
                src="@/assets/undraw/not-found.svg"
                alt="Onboarding"
                width="400"
              />
            </template>
          </we-not-found>
        </v-form>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      confirmDeleteOpened: false,
      notFound: false,
      loading: true,
      btnLoading: false,
      searchLoading: false,
      dragovered: false,
      debounce: null,
      firstEvent: true,
      product_id: [],
      reloadDeals: false,
      currentMobileNavIndex: 0,
      selectedUser: null,
      selectedProducts: [],
      selectedContacts: [],
      selectedCompanies: [],
      selectedDeals: [],
      hasAmount: false,
      updateProductInput: 0
    };
  },
  computed: {
    mobileNavigationItems() {
      return [
        {
          id: 0,
          name: this.$t("data"),
          icon: ["far", "address-card"]
        },
        {
          id: 1,
          name: this.$t("tasks"),
          icon: "tasks"
        },
        {
          id: 2,
          name: this.$t("attachments"),
          icon: "paperclip"
        },
        {
          id: 3,
          name: this.$t("comments"),
          icon: "comments"
        }
      ];
    },
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    },
    productsTotalPrice() {
      return this.$store.getters.getCurrentDealProductTotal;
    },
    steps() {
      return (
        this.mappedStages?.map(stage => {
          return {
            days: this.deal.pipeline_stages.find(e => e.stage === stage.text)
              ?.total,
            tooltip: stage.text
          };
        }) || []
      );
    },
    currentStep() {
      return this.currentStage?.name;
    },
    customFieldsInputs() {
      const isLastStage = this.currentStage.index === 9999;

      if (isLastStage) {
        let customFieldIdsInCurrentPipeline = [];

        let hasNull = false;

        this.currentPipeline.stages.forEach(e => {
          if (e.index !== 9999 && e.custom_fields === null) {
            hasNull = true;
          }

          if (e.custom_fields) {
            customFieldIdsInCurrentPipeline = [
              ...customFieldIdsInCurrentPipeline,
              ...e.custom_fields
            ];
          }
        });

        if (hasNull) return this.$store.getters.getDealCustomFieldsInputs;

        const filteredIds = [...new Set(customFieldIdsInCurrentPipeline)];

        return this.$store.getters.getDealCustomFieldsInputs.filter(e => {
          return filteredIds.includes(e.id);
        });
      }

      return this.$store.getters.getDealCustomFieldsInputs;
    },
    customFieldsInputsInStage() {
      const dealStage = this.currentStage || {};

      let fields = this.customFieldsInputs.filter(e => {
        if (!dealStage.custom_fields) {
          return true;
        }

        if (dealStage.custom_fields.includes(e.id)) {
          return true;
        }

        return false;
      });

      return fields;
    },
    customFieldsInputsWithConditions() {
      if (!this.dealCustomFields) return [];

      return this.customFieldsInputsInStage.filter(customField => {
        if (
          customField.visibility_condition?.length > 0 &&
          this.dealCustomFields
        ) {
          let conditionsSatified = 0;

          customField?.visibility_condition.forEach(condition => {
            if (
              this.dealCustomFields[condition.field_name] === condition.value
            ) {
              conditionsSatified++;
            }
          });

          return (
            conditionsSatified === customField.visibility_condition?.length
          );
        }

        return true;
      });
    },
    dealCustomFields() {
      return this.$store.getters.getDealCustomFields;
    },
    isAdmin() {
      return this.$store.getters?.getLoggedUserIsAdmin;
    },
    tags() {
      return this.$store?.getters?.getTags;
    },
    tagList() {
      if (!this.deal.tags || !this.tags) return [];

      return [...this.deal.tags, ...this.tags];
    },
    pipelines() {
      return this.loggedUser?.pipelines?.length > 0
        ? this.$store.getters.getPipelines.filter(e =>
            this.loggedUser?.pipelines?.includes(e.id)
          )
        : this.$store.getters.getPipelines;
    },
    mappedPipelines() {
      return this.pipelines.map(e => {
        return {
          text: e.name,
          value: e.id
        };
      });
    },
    currentPipeline() {
      return this.pipelines.find(e => e.id === this.deal.pipeline_id);
    },
    stages() {
      return this.currentPipeline?.stages || [];
    },
    mappedStages() {
      return this.stages.map(e => {
        return {
          text: e.name,
          value: e.id
        };
      });
    },
    currentStage() {
      return this.stages.find(e => e.id === this.deal.pipeline_stage_id) || {};
    },
    dealTasks() {
      return this.$store.getters.getDealTasks;
    },
    deal() {
      return this.$store?.getters?.getCurrentDeal;
    },
    dealUserId() {
      return this.deal?.user?.id;
    },
    dealAmount() {
      return this.deal?.products?.reduce((a, b) => a + b.price, 0);
    }
  },
  methods: {
    ...mapActions([
      "dealRequest",
      "dealTasksRequest",
      "updateDealRequest",
      "deleteDealRequest",
      "pipelinesRequest",
      "customFieldsRequest"
    ]),
    openCreateContact() {
      this.$router.push({ name: "createDealContact" });
    },
    openCreateCompany() {
      this.$router.push({ name: "createDealCompany" });
    },
    updateDealProducts(val) {
      this.$store.commit("setCurrentDealProducts", val);
      if (!this.hasAmount) {
        this.deal.amount = this.productsTotalPrice.toString();
      }
    },
    setPrevPipeline() {
      if (this.pipelines.length <= 1) return;

      const currentIndex = this.pipelines.findIndex(
        e => e.id === this.deal.pipeline_id
      );

      let previousPipelineId = this.pipelines[this.pipelines.length - 1].id;
      this.deal.pipeline_id = previousPipelineId;

      if (currentIndex > 0) {
        previousPipelineId = this.pipelines[currentIndex - 1].id;
        this.deal.pipeline_id = previousPipelineId;
      }
    },
    setNextPipeline() {
      if (this.pipelines.length <= 1) return;
      const currentIndex = this.pipelines.findIndex(
        e => e.id === this.deal.pipeline_id
      );

      let nextPipelineId = this.pipelines[0].id;
      this.deal.pipeline_id = nextPipelineId;

      if (currentIndex + 1 < this.pipelines.length) {
        nextPipelineId = this.pipelines[currentIndex + 1].id;
        this.deal.pipeline_id = nextPipelineId;
      }
    },
    selectMobileNav(index) {
      this.currentMobileNavIndex = index;
    },
    async updateDealStage(stage) {
      const newStage = this.stages.find(e => e.name === stage.tooltip) || {};

      if (newStage) {
        this.deal.pipeline_stage_id = newStage.id;

        const payload = { ...this.deal };
        delete payload.companies;
        delete payload.contacts;
        delete payload.deals;
        delete payload.products;
        this.reloadDeals = true;
        const response = await this.updateDealRequest(payload);

        this.deal.last_stage_updated_at = response.data?.last_stage_updated_at;
      }
    },
    changePipeline() {
      this.deal.pipeline_stage_id = this.mappedStages[0].value;
    },
    openRelatedDeal(deal) {
      let routeData = this.$router.resolve({
        name: "pipelineDeal",
        params: {
          dealId: deal?.pivot?.related_deal_id || deal.id
        }
      });
      window.open(routeData.href, "_blank");
    },
    setDealLostReason(val) {
      this.deal.lost_reason = val;
    },
    async updateDeal() {
      const validated = this.$refs.form.validate();
      if (validated) {
        this.btnLoading = true;
        this.deal.lost_reason =
          this.deal.lost_reason != null ? this.deal.lost_reason : "";
        let payload = { ...this.deal };

        if (this.selectedUser) {
          payload.user_id = this.selectedUser.id;
        }
        payload.companies = this.selectedCompanies.map(e => e.id);
        payload.products = this.selectedProducts.map(e => {
          return {
            id: e.id,
            amount: Number(e.amount)
          };
        });

        payload.deals = this.selectedDeals.map(e => e.id);
        payload.contacts = this.selectedContacts.map(e => e.id);
        payload.custom_fields = [this.dealCustomFields];

        this.reloadDeals = true;
        await this.updateDealRequest(payload);
        this.btnLoading = false;
      } else {
        const snackBar = {
          title: this.$t("save-error-title"),
          description: this.$t("save-error-description"),
          status: "error"
        };
        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");
      }
    },
    async deleteDeal(id) {
      this.loading = true;

      this.reloadDeals = true;
      await this.deleteDealRequest(id);
      this.loading = false;
      this.closeDeal();
    },
    dragover(e) {
      e.preventDefault();
      this.dragovered = true;
    },
    dragleave(e) {
      e.preventDefault();
      this.dragovered = false;
    },
    drop(event) {
      event.preventDefault();
      this.dragovered = false;
      this.$refs.dropFileComponent.onChange(event.dataTransfer.files);
    },
    closeDeal() {
      let menuOpened = false;
      const menus = document.querySelectorAll(".v-menu__content");

      menus.forEach(e => {
        if (e.classList.contains("menuable__content__active")) {
          menuOpened = true;
        }
      });

      if (!menuOpened) {
        if (this.$route.name === "pipelineDeal") {
          this.$router.push({
            name: "pipelines",
            query: { reload: this.reloadDeals }
          });
        } else if (this.$route.name === "tasksDeal") {
          this.$router.push({ name: "tasks" });
        } else if (this.$route.name === "deal") {
          this.$router.push({
            name: "deals",
            query: { reload: this.reloadDeals }
          });
        } else if (this.$route.name === "contactDeal") {
          this.$router.push({
            name: "contact",
            params: { contactId: Number(this.$route.params.contactId) }
          });
        } else if (this.$route.name === "dealInTasksList") {
          this.$router.push({ name: "tasksList" });
        } else if (this.$route.name === "dealInCalendar") {
          this.$router.push({ name: "tasksCalendar" });
        }
      }
    },
    openCreateDealTask() {
      this.reloadDeals = true;
      if (this.$route.name === "pipelineDeal") {
        this.$router.push({ name: "createDealTask" });
      } else if (this.$route.name === "tasksDeal") {
        this.$router.push({ name: "createDealTaskInTasks" });
      } else if (this.$route.name === "deal") {
        this.$router.push({ name: "createDealTaskInDeals" });
      } else if (this.$route.name === "contactDeal") {
        this.$router.push({ name: "createDealTaskInContactDeals" });
      } else if (this.$route.name === "dealInTasksList") {
        this.$router.push({ name: "createDealTaskInTasksList" });
      } else if (this.$route.name === "dealInCalendar") {
        this.$router.push({ name: "createDealTaskInCalendar" });
      }
    }
  },
  async mounted() {
    const request = await this.dealRequest(this.$route.params.dealId);

    document.title = this.deal.name;

    if (request?.response?.status === 404) {
      this.notFound = true;
      this.loading = false;
      return;
    }

    if (this.pipelines.length <= 0) {
      await this.pipelinesRequest();
    }

    if (this.$refs.activitiesComponent) {
      await Promise.all([
        this.customFieldsRequest({ entity: "deal" }),
        this.dealTasksRequest(this.$route.params.dealId),
        this.$refs.activitiesComponent.allNotesRequest(),
        this.$refs.dropFileComponent.allFilesRequest()
      ]);
    }

    if (Number(this.deal?.amount) > 0) {
      this.hasAmount = true;
    }

    if (this.deal?.user?.id) {
      this.selectedUser = this.deal.user;
    }

    if (this.deal.products?.length > 0) {
      this.selectedProducts = this.deal.products.map(e => {
        return {
          id: e.pivot.product_id,
          name: e.name,
          price: e.price,
          amount: e.amount,
          picture: e.picture,
          color: e.color
        };
      });
    }

    if (this.deal.contacts?.length > 0) {
      this.selectedContacts = this.deal.contacts.map(e => {
        e.id = e.pivot.contact_id;
        return e;
      });
    }

    if (this.deal.companies?.length > 0) {
      this.selectedCompanies = this.deal.companies.map(e => {
        return {
          id: e.pivot.company_id,
          company: e.company,
          picture: e.picture
        };
      });
    }

    if (this.deal.deals?.length > 0) {
      this.selectedDeals = this.deal.deals;
    }

    if (!this.currentPipeline && this.pipelines.length > 0) {
      this.deal.pipeline_id = this.pipelines[0].id;
      this.deal.pipeline_stage_id = this.pipelines[0].stages[0].id;
    }

    if (this.$route.query.reloadDeals === true) {
      this.reloadDeals = true;
    }

    this.loading = false;
  },
  destroyed() {
    this.selectedUser = null;
    this.selectedProducts = [];
    this.selectedContacts = [];
    this.selectedCompanies = [];
    this.selectedDeals = [];
    this.$store.commit("setNotes", []);
    this.$store.commit("setFiles", []);
    this.$store.commit("setCurrentDeal", { products: [] });
    this.$store.commit("setDealCustomFieldsValues", null);
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.contact) {
      this.selectedContacts.push(to.params.contact);
      this.updateDeal();
    }

    if (to.params.company) {
      this.selectedCompanies.push(to.params.company);
      this.updateDeal();
    }

    if (to.query.reloadDeals) {
      this.reloadDeals = true;
    }

    next();
  }
};
</script>

<style lang="scss">
#pipeline_deal_view {
  .shadow {
    z-index: 5;
  }

  #action_modal {
    .shadow {
      z-index: 6;
    }
  }
}

#pipeline_deal {
  position: fixed;
  background: var(--inherit);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 5;
  &.dragovered {
    .modal .dragover {
      display: flex;
    }
  }

  .modal {
    height: calc(100% - 80px);
    width: 800px;
    background: var(--background-1);
    border-radius: 10px;
    box-shadow: -2px 2px 20px rgba(0, 0, 0, 0.25);
    z-index: 4;
    overflow: hidden;
    pointer-events: initial;
    position: relative;

    @include mobile {
      height: 100%;
      width: 100%;
      border-radius: 0;
      box-shadow: none;
      display: grid;
      grid-template-rows: 188px minmax(0, 1fr) 50px;
    }

    .we-loading-overflow {
      z-index: 5;
    }

    .dragover {
      background: rgba(var(--background-1-rgb), 0.9);
      z-index: 6;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: none;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: var(--primary);
      font-weight: $semibold;
    }

    .head {
      height: 81px;
      background: var(--background-2);
      border-bottom: 1px solid var(--line);
      position: relative;
      z-index: 6;
      padding: 12px 23px 5px 23px;

      @include mobile {
        height: 100%;
        width: 100vw;
        padding: 0;
      }

      .options {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;

        @include mobile {
          top: 0;
          right: 0;
        }
      }

      .data {
        .we-deal-steps {
          position: absolute;
          bottom: -13px;
          left: 0;

          @include mobile {
            position: relative;
            bottom: 0;
          }
        }

        .deal-name {
          display: flex;
          align-items: center;
          margin-bottom: -2px;
          font-weight: $regular;
          padding-right: 80px;

          @include mobile {
            height: 50px;
            padding: 0 15px;
          }

          .icon {
            min-width: 30px;
            display: flex;
            align-items: center;
            svg {
              width: 20px;
              height: auto;
            }
          }

          .text {
            font-size: 24px;
            color: var(--text-1);

            @include mobile {
              font-size: 16px;
              max-width: calc(100% - 120px);
            }
          }
        }

        .manage-pipelines {
          display: none;
          grid-template-columns: 100px minmax(0, 1fr) 100px;
          height: 45px;
          margin-bottom: 8px;

          @include mobile {
            display: grid;
          }

          button {
            background: var(--inherit);
            display: flex;
            align-items: center;
            color: var(--text-2);
            font-size: 14px;
            font-weight: $medium;
            border: none;
            width: 100%;
            height: 100%;
            padding-left: 10px;

            &.next {
              justify-content: flex-end;
              padding: 0;
              padding-right: 10px;
            }

            svg {
              height: 17px;
              width: auto;
              margin: 0 8px;
            }
          }

          .current {
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: $medium;

            .we-label {
              font-size: 12px;
              line-height: 12px;
              color: var(--text-2);
              margin-bottom: 4px;
            }

            .pipeline-name {
              text-transform: uppercase;
              font-size: 16px;
              line-height: 16px;
              color: var(--text-1);
              width: 100%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }

        .manage-steps {
          @include mobile {
            width: 100%;
            max-width: 100%;
          }
        }

        .position {
          font-size: 14px;
          color: var(--text-2);
          font-weight: $medium;
          padding-left: 32px;

          @include mobile {
            display: none;
          }

          b {
            font-weight: $semibold;
            color: var(--text-1);
          }
        }

        .we-nav-x {
          display: none;

          @include mobile {
            display: block;
            background: var(--background-1);
            border-bottom: 1px solid var(--line);
          }
        }
      }
    }

    .body {
      position: relative;
      display: grid;
      grid-template-columns: $column-size minmax(0, 300px);

      @include mobile {
        display: block;
        overflow-y: auto;
        height: 100%;
      }

      .hide-mobile {
        @include mobile {
          display: none !important;
        }
      }

      .box {
        padding-top: 23px;
        max-height: calc(100vh - 225px);
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;

        @include mobile {
          max-height: fit-content;
          height: fit-content;
          overflow-x: hidden;
          overflow-y: hidden;
        }

        &.left {
          padding-left: 25px;
          padding-right: 25px;
          grid-column: 1;
          grid-row: 1;

          @include mobile {
            padding: 0;
            display: flex;
            flex-flow: column;
            padding-bottom: 150px;
          }
        }

        &.right {
          padding: 23px 13px 100px 12px;
          grid-column: 2;

          @include mobile {
            padding: 20px 15px 10px 15px;
          }

          .label {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: $medium;
            text-transform: uppercase;
            color: var(--text-2);
            margin-bottom: 11px;

            @include mobile {
              margin-bottom: 0;
              font-size: 12px;
              padding-left: 13px;
              text-transform: initial;
            }

            .icon {
              width: 30px;
              display: flex;
              justify-content: center;
              position: relative;
              top: -1px;

              @include mobile {
                width: 24px;
                order: 2;
              }

              svg {
                height: 14px;
                width: auto;
                color: var(--text-2);

                @include mobile {
                  height: 12px;
                }
              }
            }

            .price {
              color: var(--green);
              margin-left: 8px;
            }
          }

          .option-input {
            display: grid;
            grid-template-columns: minmax(0, 1fr) 40px;
            gap: 4px;

            &.fill {
              grid-template-columns: 1fr;
            }
          }

          .we-input {
            margin-bottom: 24px;

            @include mobile {
              margin-bottom: 20px;
            }

            &.we-input-contacts,
            &.we-input-deals {
              margin-bottom: 8px;
            }
          }

          .contact-list {
            margin-bottom: 24px;
          }

          .related-deal-list {
            margin-bottom: 24px;

            .related-deal {
              display: grid;
              grid-template-columns: $column-size 40px;
              gap: 8px;
              align-items: center;
              padding-left: 13.5px;
              margin-bottom: 8px;

              .text {
                .deal-name {
                  font-size: 14px;
                  color: var(--text-1);
                  font-weight: $medium;
                }

                .deal-description {
                  font-size: 13px;
                  color: var(--text-2);

                  b {
                    font-weight: $semibold;
                    width: 100%;
                  }

                  .description-item {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    overflow: hidden;

                    .we-text-tooltip {
                      width: 100%;
                      margin-left: 5px;
                    }

                    b {
                      display: block;
                      max-width: 145px;
                    }
                  }
                }
              }
            }
          }
        }

        section {
          margin-bottom: 24px;

          @include mobile {
            padding: 20px 15px;
          }

          &#deal-tasks {
            .fields {
              gap: 0;

              .we-deal-task {
                margin-bottom: 6px;
              }
            }
          }

          &#activities {
            position: relative;
            margin-bottom: 120px;

            @include mobile {
              margin-bottom: 24px;
            }

            .title {
              @include mobile {
                display: none;
              }
            }

            .fields .we-input {
              margin-bottom: 0;
            }
          }

          &#custom_fields {
            @include mobile {
              order: -1;
              padding-top: 0;
            }

            .we-action {
              @include mobile {
                display: none;
              }
            }
            .fields {
              .double-column {
                grid-column-start: 1;
                grid-column-end: 3;
              }
            }
          }

          .title {
            display: flex;
            align-items: center;
            font-weight: $semibold;
            color: var(--text-2);
            font-size: 16px !important;
            margin-bottom: 16px;

            .action {
              border-radius: 3px;
              background: var(--action);
              padding: 1px 10px;
              cursor: pointer;
              margin-left: 12px;

              &:hover {
                background: var(--action-hover);
              }
            }

            .icon {
              width: 30px;
              display: flex;
              align-items: center;

              svg {
                height: 18px;
                width: auto;
              }
            }
          }

          .fields {
            padding-left: 30px;
            display: grid;
            grid-template-columns: minmax(0px, 1fr) minmax(0px, 1fr);
            gap: 16px;
            row-gap: 16px;

            @include mobile {
              display: block;
              padding-left: 0;
            }

            .we-input {
              @include mobile {
                margin-bottom: 20px;
              }
            }

            &.fill {
              grid-template-columns: 1fr;
            }
          }
        }
      }
    }

    .bottom {
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 65px;
      border-top: 1px solid var(--line);
      background: var(--background-2);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 25px;
      padding-right: 20px;
      z-index: 5;

      @include mobile {
        position: fixed;
        height: 50px;
        padding: 0 15px;
      }

      button {
        min-width: 176px;

        @include mobile {
          min-width: fit-content;
        }
      }

      .history {
        height: 50px;
        display: flex;
        align-items: center;

        .icon {
          height: 100%;
          display: flex;
          align-items: center;
          margin-right: 13px;
          svg {
            width: 18px;
            height: auto;
            color: var(--text-2);
          }
        }

        .data {
          .item {
            color: var(--text-2);
            font-size: 12px;
            font-weight: $regular;

            @include mobile {
              font-size: 10px;
            }

            span {
              font-weight: $semibold;
            }
          }
        }
      }
    }

    .we-not-found {
      background: var(--background-1);
      z-index: 6;
    }
  }
}
</style>
