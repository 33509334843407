<i18n>
{
  "en": {
    "title": "New Deal",
    "not-found": "Pipelines not found",
    "not-found-description": "Check your search or filter again or click on button below to create a new one",
    "not-found-action": "Set up pipeline",
    "input-1": "Name",
    "input-2": "Owner",
    "input-3": "Products",
    "input-4": "Tags",
    "input-5": "Pipeline",
    "input-6": "Stage",
    "input-7": "Contact name",
    "input-8": "Surname",
    "input-9": "Main phone",
    "input-10": "Email",
    "input-11": "Corporate name",
    "input-12": "CNPJ",
    "input-13": "Related contacts",
    "input-14": "Related companies",
    "placeholder-1": "Enter a name",
    "placeholder-2": "Select the owner",
    "placeholder-3": "Select products",
    "placeholder-4": "Add tags",
    "placeholder-5": "Select a pipeline",
    "placeholder-6": "Select a stage",
    "placeholder-7": "Enter a name",
    "placeholder-8": "Enter a surname",
    "placeholder-9": "(00) 00000-0000",
    "placeholder-10": "Enter an email",
    "placeholder-11": "Enter a corporate name",
    "placeholder-12": "00.000.000/0000-00",
    "placeholder-13": "Select contacts",
    "placeholder-14": "Select companies",
    "switch-label-1": "Register contact",
    "switch-label-2": "Contact already exists",
    "switch-label-3": "Register company",
    "switch-label-4": "Company already exists",
    "create": "Create deal"
  },
  "pt-BR": {
    "title": "Novo card",
    "not-found": "Nenhum pipeline encontrado",
    "not-found-description": "Para criar umo card você precisa configurar um pipeline",
    "not-found-action": "Configurar pipeline",
    "input-1": "Nome",
    "input-2": "Responsável",
    "input-3": "Produtos",
    "input-4": "Etiquetas",
    "input-5": "Pipeline",
    "input-6": "Estágio",
    "input-7": "Nome do contato",
    "input-8": "Sobrenome",
    "input-9": "Telefone principal",
    "input-10": "Email",
    "input-11": "Razão social",
    "input-12": "CNPJ",
    "input-13": "Contatos relacionados",
    "input-14": "Empresas relacionadas",
    "placeholder-1": "Digite um nome",
    "placeholder-2": "Selecione o responsável",
    "placeholder-3": "Selecione os produtos",
    "placeholder-4": "Adicione etiquetas",
    "placeholder-5": "Selecione o pipeline",
    "placeholder-6": "Selecione o estágio",
    "placeholder-7": "Digite um nome",
    "placeholder-8": "Digite um sobrenome",
    "placeholder-9": "(00) 00000-0000",
    "placeholder-10": "Digite um email",
    "placeholder-11": "Digite a razão social",
    "placeholder-12": "00.000.000/0000-00",
    "placeholder-13": "Selecione os contatos",
    "placeholder-14": "Selecione as empresas",
    "switch-label-1": "Cadastrar contato",
    "switch-label-2": "Contato já existente",
    "switch-label-3": "Cadastrar empresa",
    "switch-label-4": "Empresa já existente",
    "create": "Criar card"
  }
}
</i18n>

<template>
  <modal-action @close="closeCreateDeal" :columns="2">
    <template #view>
      <div class="modal">
        <we-not-found v-if="pipelines.length === 0">
          <template #title> {{ $t("not-found") }} </template>
          <template #description>
            {{ $t("not-found-description") }}
          </template>
          <template #actions>
            <we-button
              class="green"
              :text="$t('not-found-action')"
              icon="cog"
              @click="$router.push({ name: 'pipelinesConfigs' })"
            />
          </template>
        </we-not-found>
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon icon="note-sticky" />
            </div>
            {{ $t("title") }}
          </div>
          <div class="options">
            <we-icon-button
              class="big-mobile"
              icon="times"
              @click="closeCreateDeal"
            />
          </div>
        </div>
        <div class="modal-body">
          <v-form ref="newDeal">
            <div class="fields" v-if="pipelines.length > 0">
              <we-input
                v-model="newDeal.name"
                cy="create-deal-name"
                type="text"
                :label="$t('input-1')"
                :placeholder="$t('placeholder-1')"
                isRequired
              />
              <we-input-user
                :label="$t('input-2')"
                :placeholder="$t('placeholder-2')"
                :value="newDeal.user_id"
                @input="newDeal.user_id = $event.id"
                cy="create-deal-user"
              />
              <we-input-products
                v-if="
                  pipeline &&
                    pipeline.schema &&
                    pipeline.schema.show_deal_products
                "
                :label="$t('input-3')"
                :placeholder="$t('placeholder-3')"
                v-model="newDeal.products"
                multiple
              />
              <we-input
                type="tags"
                :label="$t('input-4')"
                :placeholder="$t('placeholder-4')"
                v-model="newDeal.tags"
                :items="tagList"
                :clearable="false"
              />
              <we-input
                type="global-select"
                :label="$t('input-5')"
                :placeholder="$t('placeholder-5')"
                v-model="newDeal.pipeline_id"
                :items="mappedPipelines"
                @input="changePipeline"
              />
              <we-input
                type="global-select"
                :label="$t('input-6')"
                :placeholder="$t('placeholder-6')"
                v-model="newDeal.pipeline_stage_id"
                :items="stages"
              />
            </div>
            <div class="fields" v-if="pipelines.length > 0">
              <v-switch
                v-model="enableContact"
                :class="{ 'double-column': !enableContact }"
                :label="$t('switch-label-1')"
                :hide-details="true"
              />
              <template v-if="enableContact">
                <v-switch
                  v-model="existingContact"
                  :label="$t('switch-label-2')"
                  :hide-details="true"
                />
                <template v-if="existingContact">
                  <we-input-contacts
                    class="double-column"
                    multiple
                    :label="$t('input-13')"
                    :placeholder="$t('placeholder-13')"
                    v-model="newDeal.contacts"
                  />
                </template>
                <template v-else>
                  <we-input
                    v-model="newContact.first_name"
                    isRequired
                    type="text"
                    :label="$t('input-7')"
                    :placeholder="$t('placeholder-7')"
                  />
                  <we-input
                    v-model="newContact.last_name"
                    isRequired
                    type="text"
                    :label="$t('input-8')"
                    :placeholder="$t('placeholder-8')"
                  />
                  <we-input
                    v-model="newContact.phone1"
                    :label="$t('input-9')"
                    :placeholder="$t('placeholder-9')"
                    type="text"
                    :mask="$root.$t('phone-mask')"
                  />
                  <we-input
                    v-model="newContact.email"
                    type="email"
                    :label="$t('input-10')"
                    :placeholder="$t('placeholder-10')"
                  />
                </template>
              </template>
              <v-switch
                v-if="
                  pipeline &&
                    pipeline.schema &&
                    pipeline.schema.show_deal_companies
                "
                v-model="enableCompany"
                :label="$t('switch-label-3')"
                :class="{ 'double-column': !enableCompany }"
                :hide-details="true"
              />
              <template v-if="enableCompany">
                <v-switch
                  v-model="existingCompany"
                  :label="$t('switch-label-4')"
                  :hide-details="true"
                />
                <template v-if="existingCompany">
                  <we-input-companies
                    class="double-column"
                    multiple
                    :label="$t('input-14')"
                    :placeholder="$t('placeholder-14')"
                    v-model="newDeal.companies"
                  />
                </template>
                <template v-else>
                  <we-input
                    v-model="newCompany.company"
                    isRequired
                    type="text"
                    :label="$t('input-11')"
                    :placeholder="$t('placeholder-11')"
                  />
                  <we-input
                    v-model="newCompany.document"
                    type="text"
                    :label="$t('input-12')"
                    :placeholder="$t('placeholder-12')"
                    :mask="'##.###.###/####-##'"
                  />
                </template>
              </template>
            </div>
          </v-form>
        </div>
        <div class="bottom">
          <we-button
            class="green"
            cy="create-deal"
            :text="$t('create')"
            icon="plus"
            :disabled="pipelines.length === 0"
            :loading="btnLoading"
            @click="createDeal"
          />
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      btnLoading: false,
      loading: true,
      searchLoading: false,
      stageName: "",
      enableContact: false,
      existingContact: false,
      enableCompany: false,
      existingCompany: false,
      newCompany: {
        company: ""
      },
      newContact: {
        first_name: "",
        last_name: "",
        email: ""
      },
      newDeal: {
        name: "",
        user_id: null,
        products: [],
        contacts: [],
        companies: [],
        tags: [],
        lost_reason: "",
        color: "blue",
        pipeline_stage_id: null,
        pipeline_id: 107,
        amount: 0
      }
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    },
    tags() {
      return this.$store?.getters?.getTags;
    },
    tagList() {
      if (!this.newDeal.tags || !this.tags) return [];

      return [...this.newDeal.tags, ...this.tags];
    },
    dealTasks() {
      return this.$store.getters.getDealTasks;
    },
    pipeline() {
      return this.pipelines[this.$store.getters.getCurrentPipelineIndex];
    },
    pipelines() {
      return this.loggedUser?.pipelines?.length > 0
        ? this.$store.getters.getPipelines.filter(e =>
            this.loggedUser?.pipelines?.includes(e.id)
          )
        : this.$store.getters.getPipelines;
    },
    mappedPipelines() {
      return this.pipelines.map(e => {
        return {
          text: e.name,
          value: e.id
        };
      });
    },
    stages() {
      return this.pipelines
        .find(e => e.id === this.newDeal.pipeline_id)
        ?.stages.map(e => {
          return {
            text: e.name,
            value: e.id
          };
        });
    }
  },
  methods: {
    ...mapActions([
      "pipelinesRequest",
      "dealRequest",
      "dealTasksRequest",
      "createDealRequest",
      "deleteDealRequest",
      "createCompanyRequest",
      "createContactRequest"
    ]),
    changePipeline() {
      this.newDeal.pipeline_stage_id = this.stages[0].value;
    },
    closeCreateDeal() {
      if (this.$route.name === "createDealInPipelines") {
        this.$router.push({ name: "pipelines" });
      } else if (this.$route.name === "createDealInDeals") {
        this.$router.push({ name: "deals" });
      }
    },
    setDealLostReason(val) {
      this.newDeal.lost_reason = val;
    },
    async createDeal() {
      const validated = this.$refs.newDeal.validate();

      if (validated) {
        this.btnLoading = true;
        const payload = this.newDeal;
        payload.amount = 0;

        if (this.enableCompany && !this.existingCompany) {
          const companyResponse = await this.createCompanyRequest(
            this.newCompany
          );

          if (companyResponse?.data?.id) {
            payload.companies = [companyResponse?.data?.id];
            this.newContact.company_id = companyResponse?.data?.id;
          } else {
            this.btnLoading = false;
            return;
          }
        } else if (payload.companies.length > 0) {
          payload.companies = payload.companies.map(e => e.id);
        }

        if (this.enableContact && !this.existingContact) {
          const contactResponse = await this.createContactRequest(
            this.newContact
          );

          if (contactResponse?.data?.id) {
            payload.contacts = [contactResponse.data.id];
          } else {
            this.btnLoading = false;
            return;
          }
        } else if (payload.contacts.length > 0) {
          payload.contacts = payload.contacts.map(e => e.id);
        }

        if (payload?.products?.length > 0) {
          payload.products?.forEach(e => {
            payload.amount += Number(e.price) * Number(e.amount);
          });
        }

        payload.products = payload.products.map(e => {
          return {
            id: e.id,
            amount: 1
          };
        });

        const response = await this.createDealRequest(payload);

        this.btnLoading = false;

        if (this.$route.name === "createDealInPipelines") {
          this.$router.push({
            name: "pipelineDeal",
            params: { dealId: response.data.id },
            query: { reloadDeals: true }
          });
        } else if (this.$route.name === "createDealInDeals") {
          this.$router.push({
            name: "deal",
            params: { dealId: response.data.id },
            query: { reload: true }
          });
        }
      }
    },
    async deleteDeal(id) {
      this.loading = true;
      await this.deleteDealRequest(id);
      this.loading = false;
      this.$emit("close");
    },
    dragover(e) {
      e.preventDefault();
      this.dragovered = true;
    },
    dragleave(e) {
      e.preventDefault();
      this.dragovered = false;
    },
    drop(event) {
      event.preventDefault();
      this.dragovered = false;
      this.$refs.dropFileComponent.onChange(event.dataTransfer.files);
    },
    closeDeal() {
      if (this.$route.name === "pipelineDeal") {
        this.$router.push({ name: "pipelines" });
      } else if (this.$route.name === "tasksDeal") {
        this.$router.push({ name: "tasks" });
      }
    },
    openCreateDealTask() {
      if (this.$route.name === "pipelineDeal") {
        this.$router.push({ name: "createDealTask" });
      } else if (this.$route.name === "tasksDeal") {
        this.$router.push({ name: "createDealTaskInTasks" });
      }
    }
  },
  async mounted() {
    this.$store.commit("clearDealCustomFieldsValues");

    if (this.pipelines.length <= 0) {
      await this.pipelinesRequest();
    }

    if (this.pipelines.length > 0) {
      this.newDeal.pipeline_id = this.pipelines[
        this.$store.getters.getCurrentPipelineIndex
      ].id;
      this.changePipeline();
    }

    this.loading = false;
  }
};
</script>

<style lang="scss" scoped>
.modal {
  .modal-body {
    min-height: 250px;
  }
}
</style>
